.header{
  position: fixed;
}

img{
  max-width: 100%;
  max-height: 100%;
}

.navbar{
  display: flex;
  justify-content: space-between;
}

.navbar{
  letter-spacing: 0px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 50px;   
}

.navbar a{
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
}

.navbar a img{
  width: 50px;
  border-radius: 100px;
  box-shadow: black 0px 0px 5px -1px;
  pointer-events: none;
}

.navbar a:nth-of-type(1) {
  margin-left: 50px;
}
.navbar a:nth-of-type(2) {
  padding-top: 35px;
  margin-right: 50px;
}

.navbar a:nth-of-type(2):hover {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
}

h1:nth-of-type(1) {
  font-size: 30px;
  font-weight: bolder;
}

h1{
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
}

.App {
  text-align: center;
}

body{
  background-color: #2f3334;
  color: white;
  /* font-weight: 900; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: brandon-grotesque, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 2px;
  font-size: 20px;
}

.container{
  margin-top: 50px;
  box-shadow: black 0px 10px 20px -5px;
}

button{
  background-color: #2f3334;
  box-shadow: black 0px 10px 20px -5px;
  color: white;
  border: none;
  margin: 5px;
  padding: 10px 12px;
  border-radius: 20px;
}

button:hover{
  cursor: pointer;
  box-shadow: black 0px 5px 20px -1px;
}

.canvas{
  box-shadow: black 0px 10px 20px -5px;
}

polyline{
  stroke-width: 5px;
  stroke: white;
  width: 40px;
}


.container{
  width: 100%;
  height: 100%;
  margin: 20px auto;
  padding-bottom: 20px;
  padding-top: 20px;
}

.a{
  text-decoration: none;
}

footer{
  margin-top: 100px;
}

.copyright{
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 40px;

}

.links{
  text-align: center;
  margin-top: 20px;
}


.links a img{
  max-width: 100%;
  width: 50%;
  padding-top: 13px;
  pointer-events: none;
}

#musicLink a img{
  width: 80%;
  padding-top: 5px;
  pointer-events: none;
}



.links a{
  display: inline-block;
  background-color: #2f3334;
  border-radius: 100px;
  margin: 0 0.5rem;
  max-width: 100%;
  width: 50px;
  height: 50px;
  box-shadow: black 0px 10px 20px -5px;
  margin-bottom: 40px;
}

.links a:hover{
  box-shadow: black 0px 5px 20px -1px;
}




@media (min-width: 650px) {

  .container{
    width: 70%;
    height: 70%;
  }
  
}
